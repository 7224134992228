import React, { Component, ContextType } from "react";
import { Button, Select, Modal } from "antd";
import styles from "./Header.module.scss";
import { RedoOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { rootStoreContext } from "contexts";
import { SelectValue } from "antd/lib/select";
import classNames from "classnames";

const { Option } = Select;

const differentChartLabels = [
  "View All",
  "H Movement x V Movement",
  "H Movement x Speed",
  "V Movement x Speed",
  "Spin x Speed",
  "H Movement x Spin",
  "V Movement x Spin",
];

@observer
class Header extends Component {
  static contextType = rootStoreContext;
  context!: ContextType<typeof rootStoreContext>;

  selectChart = (e: SelectValue) => {
    const { chartingStore } = this.context;
    chartingStore.selectedSingleChart = e.toString();
  };

  render() {
    const {
      navigationStore,
      chartingStore,
      pitchClassifierStore,
      pitchClassifierApi,
    } = this.context;
    const { dropdownSelectedOptions } = navigationStore;

    const chartLabelSelections = differentChartLabels.map((label) => {
      return (
        <Option key={label} value={label}>
          {label}
        </Option>
      );
    });

    const pitcherIsSet =
      dropdownSelectedOptions.selectedPitcherInfo?.id !== undefined;
    const dataIsSet = chartingStore.pitchersDataByPitchType.size > 0;

    return (
      <div className={styles.container}>
        <div className={classNames(styles.section, styles.buttons)}>
          <Button
            onClick={() => {
              chartingStore.fetchAndSortLiveGameData(
                navigationStore.dropdownSelectedOptions.selectedGamePks
              );
              pitchClassifierStore.fetchPitcherArsenal();
            }}
            className={styles.button}
          >
            <RedoOutlined />
            Refresh Pitches
          </Button>
          <Button
            className={styles.button}
            disabled={!pitcherIsSet || !dataIsSet}
            onClick={chartingStore.selectAllPitches}
          >
            Select all
          </Button>
          <Button
            disabled={!pitcherIsSet || !dataIsSet}
            onClick={() => {
              if (dropdownSelectedOptions.selectedPitcherInfo?.id) {
                chartingStore.loading = true;
                pitchClassifierApi
                  .classifyPitches(
                    dropdownSelectedOptions.selectedPitcherInfo?.id,
                    chartingStore.allSelectedPitches
                  )
                  .then(() => {
                    chartingStore.loading = false;
                  });
              }
            }}
            className={styles.button}
          >
            Classify Pitches
          </Button>
          <Button
            disabled={!pitcherIsSet}
            onClick={() => {
              if (dropdownSelectedOptions.selectedPitcherInfo?.id) {
                pitchClassifierApi.deleteTrainingData(
                  dropdownSelectedOptions.selectedPitcherInfo?.id
                );
              }
            }}
            className={styles.button}
          >
            Delete Training
          </Button>
          <Button
            disabled={!pitcherIsSet || !dataIsSet}
            onClick={() => {
              if (dropdownSelectedOptions.selectedPitcherInfo?.id) {
                pitchClassifierApi.trainPitcher(
                  dropdownSelectedOptions.selectedPitcherInfo?.id,
                  chartingStore.allSelectedPitches
                );
              }
            }}
            className={styles.button}
          >
            Train Pitches
          </Button>
          <Button
            disabled={!pitcherIsSet || !dataIsSet}
            onClick={() => {
              if (dropdownSelectedOptions.selectedPitcherInfo?.id) {
                chartingStore.loading = true;
                pitchClassifierApi
                  .trainNeuralNet(
                    dropdownSelectedOptions.selectedPitcherInfo?.id
                  )
                  .then((data) => {
                    Modal.info({
                      title: `${dropdownSelectedOptions.selectedPitcherInfo?.name} training results`,
                      content: (
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          ${data.response}
                        </div>
                      ),
                      width: 600,
                    });
                    chartingStore.loading = false;
                  });
              }
            }}
            className={styles.button}
          >
            Train NN
          </Button>
        </div>
        <div>
          <div className={styles.pitcher}>
            {dropdownSelectedOptions.selectedPitcherInfo?.name}
          </div>
        </div>
        <div>
          <div className={styles["chart-selection"]}>
            <Select
              size="middle"
              style={{ minWidth: 200 }}
              placeholder="Select a Chart to View"
              defaultValue="View All"
              onSelect={this.selectChart}
            >
              {chartLabelSelections}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
