import { computed, observable } from "mobx";
import RootStore from "./RootStore";

type AuthHeader = { Authorization: string } | {};

export default class AuthStore {
  rootStore: RootStore;
  @observable user?: User = undefined;
  @observable accessToken?: string = undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    const authService = rootStore.authService;
    const tokenManager = authService.getTokenManager();

    if (tokenManager) {
      tokenManager.on("expired", async () => {
        this.accessToken = await authService.getAccessToken();
      });
    }

    authService.on("authStateChange", async () => {
      const state = authService.getAuthState();
      if (state.isAuthenticated) {
        this.user = authService.getUser();
      }
    });
  }

  @computed get authHeader(): AuthHeader {
    return this.accessToken
      ? { Authorization: `Bearer ${this.accessToken}` }
      : {};
  }
}
