import { reaction } from "mobx";
import { AuthService } from "@okta/okta-react";
import StatsApi from "api/StatsApi";
import PitchClassifierApi from "api/PitchClassifierApi";
import NavigationStore from "./NavigationStore";
import ChartingStore from "./ChartingStore";
import PitchClassifierStore from "./PitchClassifierStore";
import MessageStore from "./MessageStore";
import AuthStore from "./AuthStore";

const { protocol, host } = window.location;

const AUTH_CONFIG = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${protocol}//${host}/implicit/callback`,
};

export default class RootStore {
  authService = new AuthService(AUTH_CONFIG) as AuthService;
  statsApi = new StatsApi();
  messageStore = new MessageStore();

  pitchClassifierApi = new PitchClassifierApi(this);
  navigationStore = new NavigationStore(this);
  chartingStore = new ChartingStore(this);
  pitchClassifierStore = new PitchClassifierStore(this);
  authStore = new AuthStore(this);

  constructor() {
    reaction(
      () => this.chartingStore.handednessFilter,
      () => {
        this.chartingStore.fetchAndSortLiveGameData(
          this.navigationStore.dropdownSelectedOptions.selectedGamePks
        );
      }
    );

    reaction(
      () => [
        this.navigationStore.dropdownSelectedOptions.selectedSportId,
        this.navigationStore.dropdownSelectedOptions.selectedSeasons,
        this.navigationStore.dropdownSelectedOptions.selectedDate,
      ],
      () => {
        this.navigationStore.resetPreviouslyPerformedSearch();
      }
    );

    reaction(
      () =>
        this.navigationStore.dropdownSelectedOptions.selectedPitcherInfo?.id,
      (pitcherId) => {
        this.chartingStore.resetPitcherData();
        if (pitcherId) {
          this.pitchClassifierStore.fetchPitcherArsenal();
        }
      }
    );
  }
}
