import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Layout } from "antd";
import { Redirect } from "react-router-dom";

import NavigationContainer from "./containers/navigationContainer";
import ChartingContainer from "./containers/chartingContainer";
import Footer from "./containers/footer";
import Header from "./containers/header";
import PitchClassifierContainer from "./containers/pitchClasssifierContainer";
import { Routes } from "./enums/routes";

import styles from "./App.module.scss";
import classNames from "classnames";

const Content = Layout.Content;

function App() {
  const { authState } = useOktaAuth();
  return authState.isAuthenticated ? (
    <Layout>
      <Content>
        <div className={styles.app}>
          <div className={classNames(styles.sidebar)}>
            <NavigationContainer />
          </div>
          <div className={styles.content}>
            <div className={classNames(styles.item, styles.header)}>
              <Header />
            </div>
            <div className={classNames(styles.item, styles.graphs)}>
              <ChartingContainer />
            </div>
            <div className={classNames(styles.item, styles.buttons)}>
              <PitchClassifierContainer />
            </div>
            <div className={classNames(styles.item, styles.footer)}>
              <Footer />
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  ) : (
    <Redirect to={Routes.Login} />
  );
}

export default App;
