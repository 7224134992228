import { observable, action } from "mobx";
import RootStore from "./RootStore";
import { Stats, Pitcher, Split } from "api/StatsApi";
import moment from "moment";
import _ from "lodash";

interface PitcherInfo {
  name: string;
  id: number;
}

export interface GameType {
  id: string;
  description: string;
}

export interface NavigateByDateData {
  games: {
    gamePk: string;
    gameType: string;
    abbrev: string;
  }[];
  pitchers: Pitcher[];
}

export interface NavigateByPitcherData {
  pitchersDropdown: Pitcher[];
  gameTypesDropdown: GameType[];
  allSportsDropdown: { abbreviation: string; id: string }[];
  seasonsDropdown: string[];
  gamePksDropdown: number[];
}

export interface DropdownSelectedOptions {
  selectedGamePks: number[];
  selectedSportId: number;
  selectedSeasons: string[];
  selectedGameType: string;
  selectedPitcherInfo?: PitcherInfo;
  selectedDate: string;
}

const sortPitchersByReverseOrderOfAppearance = (order: number[]) => (
  a: Pitcher,
  b: Pitcher
) => (order.indexOf(parseInt(a.id)) > order.indexOf(parseInt(b.id)) ? -1 : 1);

class NavigationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = false;
  @observable currentSeason?: string = undefined;
  @observable stats: Stats[] = [];
  @observable splits: Split[] = [];

  //holds ALL the selected options from the dropdown Menus for Both NavByPitcher & Date
  @observable dropdownSelectedOptions: DropdownSelectedOptions = {
    selectedGamePks: [],
    selectedSportId: 0,
    selectedSeasons: [],
    selectedGameType: "R",
    selectedPitcherInfo: undefined,
    selectedDate: "",
  };

  //holds ALL the necessary dropdown info for NavigateByPitcher
  @observable NavByPitcherDropdownOptions: NavigateByPitcherData = {
    pitchersDropdown: [],
    gameTypesDropdown: [],
    allSportsDropdown: [],
    gamePksDropdown: [],
    seasonsDropdown: [],
  };

  //holds ALL the necessary dropdown/select info for NavigateByDate
  @observable NavByDateDropdownOptions: NavigateByDateData = {
    games: [],
    pitchers: [],
  };

  @observable errorOccurred: boolean = false;

  @action resetSelectedGamePks = () => {
    this.dropdownSelectedOptions.selectedGamePks = [];
    this.NavByPitcherDropdownOptions.gamePksDropdown = [];
  };

  @action resetSelectedOptions = () => {
    const { dropdownSelectedOptions } = this;
    const resetOptions: DropdownSelectedOptions = {
      selectedGamePks: [],
      selectedSportId: 0,
      selectedSeasons: this.currentSeason
        ? [this.currentSeason.toString()]
        : [],
      selectedGameType: "R",
      selectedPitcherInfo: undefined,
      selectedDate: moment(new Date()).format("MM/DD/YYYY"),
    };
    Object.assign(dropdownSelectedOptions, resetOptions);
    this.stats = [];
  };

  @action resetPreviouslyPerformedSearch = () => {
    const { dropdownSelectedOptions, NavByPitcherDropdownOptions } = this;
    dropdownSelectedOptions.selectedGamePks = [];
    dropdownSelectedOptions.selectedPitcherInfo = undefined;
    NavByPitcherDropdownOptions.pitchersDropdown = [];
    NavByPitcherDropdownOptions.gamePksDropdown = [];
    this.splits = [];
    this.stats = [];
  };

  @action fetchGamesForSelectedPitcher = () => {
    const {
      selectedPitcherInfo,
      selectedSeasons,
      selectedGameType,
      selectedSportId,
    } = this.dropdownSelectedOptions;
    if (selectedPitcherInfo?.id) {
      this.splits = [];
      this.stats = [];
      this.loading = true;
      const seasonRequests = selectedSeasons.map((season) => {
        return this.rootStore.statsApi
          .fetchGames(
            selectedSportId,
            season,
            selectedGameType,
            selectedPitcherInfo?.id
          )
          .then((data) => {
            this.stats = data.stats;

            for (let stat of this.stats) {
              for (let item of stat.splits) {
                let sortByDate = [...this.splits, item];
                this.splits = [...sortByDate].sort((a, b) => {
                  let earlierGame: Date = new Date(a.date);
                  let laterGame: Date = new Date(b.date);
                  return earlierGame.valueOf() - laterGame.valueOf();
                });
                this.NavByPitcherDropdownOptions.gamePksDropdown = [
                  ...this.NavByPitcherDropdownOptions.gamePksDropdown,
                  item.game.gamePk,
                ];
              }
            }
          })
          .catch(() => {
            this.errorOccurred = true;
            console.error("No data available");
          });
      });
      Promise.all(seasonRequests).then(() => (this.loading = false));
    } else {
      this.errorOccurred = true;
      console.error("Can't fetch games with no pitcherId set");
    }
  };

  //fetches all available sportIds, sportId 1 = Major League, etc
  @action fetchSportIds = async () => {
    const { statsApi } = this.rootStore;
    this.loading = true;
    await statsApi
      .fetchSportIds()
      .then((s) => {
        let allSports = s.sports.map((sport) => {
          return { abbreviation: sport.abbreviation, id: sport.id.toString() };
        });
        this.NavByPitcherDropdownOptions.allSportsDropdown = allSports;
      })
      .catch((err) => console.error(err))
      .finally(() => (this.loading = false));
  };

  //fetches all available gameTypes
  @action fetchGameTypes = async () => {
    const { statsApi } = this.rootStore;
    this.loading = true;
    await statsApi
      .fetchGameTypes()
      .then((data) => {
        let filteredGameTypes = data.filter((item) => {
          return item.id !== "I" && item.id !== "N";
        });

        let allGameTypes = filteredGameTypes.map((d) => {
          return {
            id: d.id,
            description: d.description,
          };
        });
        this.NavByPitcherDropdownOptions.gameTypesDropdown = allGameTypes;
      })
      .catch((err) => console.error(err))
      .finally(() => (this.loading = false));
  };

  fetchAllPitchersForSeason = () => {
    const { statsApi } = this.rootStore;
    const {
      selectedSportId,
      selectedSeasons,
      selectedGameType,
    } = this.dropdownSelectedOptions;

    let allPitchers: {
      firstName: string;
      lastName: string;
      lastFirstName: string;
      fullName: string;
      id: string;
    }[] = [];
    this.loading = true;
    const seasonRequests = selectedSeasons.map((season) => {
      return statsApi
        .fetchAllPitchersForSeason(selectedSportId, season, selectedGameType)
        .then((data) => {
          const pitchersPerSeason = data.stats[0].splits.map((split) => {
            const { player } = split;
            return {
              firstName: player.firstName,
              lastName: player.lastName,
              lastFirstName: player.lastFirstName,
              fullName: player.fullName,
              id: player.id.toString(),
            };
          });
          Array.prototype.push.apply(allPitchers, pitchersPerSeason);
          this.NavByPitcherDropdownOptions.pitchersDropdown = _.uniqBy(
            allPitchers,
            (pitcher) => pitcher.id
          );
        })
        .catch((err) => console.error(err));
    });
    Promise.all(seasonRequests).then(() => (this.loading = false));
  };

  //Fetches all games based on a chosen date
  fetchGamesForDate = async () => {
    const { statsApi } = this.rootStore;
    this.loading = true;
    await statsApi
      .fetchGamesForDate(
        this.dropdownSelectedOptions.selectedDate,
        this.dropdownSelectedOptions.selectedSportId
      )
      .then((data) => {
        if (data.dates[0]) {
          this.NavByDateDropdownOptions.games = data.dates[0].games.map(
            (game) => {
              const gamePk = game.gamePk;
              const gameType = game.gameType;
              const awayTeamAbbrev = game.teams.away.team.abbreviation;
              const homeTeamAbbrev = game.teams.home.team.abbreviation;
              const gameObject = {
                gamePk: gamePk.toString(),
                gameType,
                abbrev: `${awayTeamAbbrev} @ ${homeTeamAbbrev}`,
              };
              return gameObject;
            }
          );
        } else {
          this.NavByDateDropdownOptions.games = [];
        }
      })
      .catch((err) => console.error(err))
      .finally(() => (this.loading = false));
  };

  //Fetches for pitchers based on a chosen Game, and makes sure those pitchers were active
  //aka not in the bullpen.
  fetchPitchersForDateNavigation = async () => {
    const { statsApi } = this.rootStore;
    this.loading = true;
    await statsApi
      .fetchPitchersForDateNavigation(
        this.dropdownSelectedOptions.selectedGamePks[0].toString()
      )
      .then((data) => {
        const awayTeam = data.teams.away;
        const homeTeam = data.teams.home;
        const awayTeamPitcherOrder = data.teams.away.pitchers;
        const homeTeamPitcherOrder = data.teams.home.pitchers;
        const awayTeamPitchers: Pitcher[] = [];
        const homeTeamPitchers: Pitcher[] = [];
        for (let ID in awayTeam.players) {
          const player = awayTeam.players[ID].person;
          if (
            awayTeam.players[ID].position.code === "1" &&
            !awayTeam.bullpen.includes(player.id)
          ) {
            const p: Pitcher = {
              fullName: player.fullName,
              firstName: player.firstName,
              lastName: player.lastName,
              lastFirstName: player.lastFirstName,
              id: player.id.toString(),
            };
            awayTeamPitchers.push(p);
          }
        }
        for (let ID in homeTeam.players) {
          const player = homeTeam.players[ID].person;
          if (
            homeTeam.players[ID].position.code === "1" &&
            !homeTeam.bullpen.includes(player.id)
          ) {
            const p: Pitcher = {
              fullName: player.fullName,
              firstName: player.firstName,
              lastName: player.lastName,
              lastFirstName: player.lastFirstName,
              id: player.id.toString(),
            };
            homeTeamPitchers.push(p);
          }
        }
        // sort each team's pitchers by when they appeared in the game, reverse chron
        awayTeamPitchers.sort(
          sortPitchersByReverseOrderOfAppearance(awayTeamPitcherOrder)
        );
        homeTeamPitchers.sort(
          sortPitchersByReverseOrderOfAppearance(homeTeamPitcherOrder)
        );

        const allPitchers = homeTeamPitchers.concat(awayTeamPitchers);
        this.NavByDateDropdownOptions.pitchers = allPitchers;
      })
      .catch((err) => console.error(err))
      .finally(() => (this.loading = false));
  };

  @action fetchLatestSeason = async () => {
    const { statsApi } = this.rootStore;
    this.loading = true;
    await statsApi
      .fetchLatestSeason()
      .then((data) => {
        const latestSeason = parseInt(data.seasons[0].seasonId);
        const seasonList = [];
        for (let season = latestSeason; season >= 2007; season--) {
          seasonList.push(season.toString());
        }
        this.NavByPitcherDropdownOptions.seasonsDropdown = seasonList;
        this.currentSeason = latestSeason.toString();
      })
      .catch((err) => console.error(err))
      .finally(() => (this.loading = false));
  };
}

export default NavigationStore;
