export const pitchTypes = [
  {
    label: "FA",
    color: "rgb(235, 229, 196)",
  },
  {
    label: "FT",
    color: "rgb(102, 148, 176)",
  },
  {
    label: "SI",
    color: "rgb(8, 100, 175)",
  },
  {
    label: "FF",
    color: "rgb(227, 132, 4)",
  },
  {
    label: "FC",
    color: "rgb(227, 247, 161)",
  },
  {
    label: "FS",
    color: "rgb(255, 211, 19)",
  },
  {
    label: "FO",
    color: "rgb(117, 176, 2)",
  },
  {
    label: "CH",
    color: "rgb(165, 186, 78)",
  },
  {
    label: "SC",
    color: "rgb(209, 222, 229)",
  },
  {
    label: "ST",
    color: "rgb(255, 20, 147)",
  },
  {
    label: "SL",
    color: "rgb(201, 201, 159)",
  },
  {
    label: "SV",
    color: "rgb(0, 255, 255)",
  },
  {
    label: "CU",
    color: "rgb(27, 151, 217)",
  },
  {
    label: "CS",
    color: "rgb(139, 0, 139)",
  },
  {
    label: "KC",
    color: "rgb(240, 94, 41)",
  },
  {
    label: "KN",
    color: "rgb(204, 51, 0)",
  },
  {
    label: "GY",
    color: "rgb(102, 148, 176)",
  },
  {
    label: "EP",
    color: "rgb(82, 212, 201)",
  },
];

export const labelToColorMap: {
  [key: string]: string;
} = pitchTypes.reduce(
  (acc, pitchType) => ({
    ...acc,
    [pitchType.label]: pitchType.color,
  }),
  {}
);

export const allPitchTypes: string[] = pitchTypes.map(
  (pitchType) => pitchType.label
);
