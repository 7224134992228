import React, { Component, ContextType } from "react";
import NavigateByPitcher from "components/navigateByPitcher";
import NavigateByDate from "components/navigateByDate";
import { Radio } from "antd";
import styles from "./NavigationContainer.module.scss";
import { observer } from "mobx-react";
import { rootStoreContext } from "contexts";
import { RadioChangeEvent } from "antd/lib/radio";
import Spinner from "components/spinner";

interface NavigationState {
  navigationSelection: string;
  fetchingData: boolean;
}

@observer
class NavigationContainer extends Component<{}, NavigationState> {
  static contextType = rootStoreContext;
  context!: ContextType<typeof rootStoreContext>;

  state = {
    navigationSelection: "pitcher",
    fetchingData: false,
  };

  async componentDidMount() {
    const { navigationStore } = this.context;
    this.setState({ fetchingData: true });
    await navigationStore.fetchSportIds();
    await navigationStore.fetchGameTypes();
    await navigationStore
      .fetchLatestSeason()
      .then(() => this.setState({ fetchingData: false }));
  }

  navigationSelection = (type: string) => {
    this.setState({ navigationSelection: type });
  };

  render() {
    const { navigationStore, chartingStore } = this.context;
    const { dropdownSelectedOptions } = navigationStore;
    const {
      gameTypesDropdown,
      pitchersDropdown,
      seasonsDropdown,
      allSportsDropdown,
      gamePksDropdown,
    } = navigationStore.NavByPitcherDropdownOptions;

    return this.state.fetchingData ? null : (
      <div className={styles["outer-container"]}>
        {navigationStore.loading ? (
          <div className={styles["loading-container"]}>
            <Spinner size="large" />
          </div>
        ) : null}
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            this.setState({ navigationSelection: e.target.value });
          }}
          value={this.state.navigationSelection}
          className={styles.buttons}
        >
          <Radio.Button
            value="pitcher"
            className={styles.button}
            onClick={() => {
              this.navigationSelection("pitcher");
              dropdownSelectedOptions.selectedGamePks = [];
            }}
          >
            Pitcher
          </Radio.Button>
          <Radio.Button
            value="date"
            className={styles.button}
            onClick={() => {
              this.navigationSelection("date");
              dropdownSelectedOptions.selectedGamePks = [];
            }}
          >
            Date
          </Radio.Button>
        </Radio.Group>
        <div className={styles["inner-container"]}>
          {this.state.navigationSelection === "pitcher" ? (
            <NavigateByPitcher
              errorOccurred={navigationStore.errorOccurred}
              gameTypes={gameTypesDropdown}
              pitchers={pitchersDropdown}
              seasons={seasonsDropdown}
              sports={allSportsDropdown}
              currentSeason={navigationStore.currentSeason}
              splits={navigationStore.splits}
              availableGamePks={gamePksDropdown}
              selectedGamePks={dropdownSelectedOptions.selectedGamePks}
              currentPitcherId={
                navigationStore.dropdownSelectedOptions.selectedPitcherInfo?.id
              }
              setSportId={(sportId) =>
                (dropdownSelectedOptions.selectedSportId = sportId)
              }
              setPitcherInfo={(id: number, name: string) =>
                (dropdownSelectedOptions.selectedPitcherInfo = { id, name })
              }
              setGameType={(gameType) =>
                (dropdownSelectedOptions.selectedGameType = gameType)
              }
              setSeason={(season) =>
                (dropdownSelectedOptions.selectedSeasons = season)
              }
              setSelectedGamePks={(selectedGamePks) => {
                dropdownSelectedOptions.selectedGamePks = selectedGamePks;
                chartingStore.fetchAndSortLiveGameData(selectedGamePks);
              }}
              setPitcherData={() => {
                navigationStore.errorOccurred = false;
                navigationStore.fetchGamesForSelectedPitcher();
              }}
              fetchAllPitchers={() => {
                navigationStore.fetchAllPitchersForSeason();
              }}
              resetSelectedOptions={() => {
                navigationStore.resetSelectedOptions();
              }}
              resetSelectedGamePks={() => {
                navigationStore.resetSelectedGamePks();
              }}
            />
          ) : (
            <NavigateByDate
              allGames={navigationStore.NavByDateDropdownOptions.games}
              pitchers={navigationStore.NavByDateDropdownOptions.pitchers}
              sports={allSportsDropdown}
              currentPitcherId={
                navigationStore.dropdownSelectedOptions.selectedPitcherInfo?.id
              }
              currentDateInput={
                navigationStore.dropdownSelectedOptions.selectedDate
              }
              currentGamePkInput={
                navigationStore.dropdownSelectedOptions.selectedGamePks
              }
              setSportId={(sportId) =>
                (dropdownSelectedOptions.selectedSportId = sportId)
              }
              setDate={(date) => {
                dropdownSelectedOptions.selectedDate = date.format(
                  "MM/DD/YYYY"
                );
                dropdownSelectedOptions.selectedSeasons = [date.format("YYYY")];
                navigationStore.fetchGamesForDate();
              }}
              setGamePk={(gamePk: string) => {
                dropdownSelectedOptions.selectedGamePks = [parseInt(gamePk)];
                navigationStore.fetchPitchersForDateNavigation();
              }}
              setGameType={(gameType: string) => {
                dropdownSelectedOptions.selectedGameType = gameType;
              }}
              setPitcherInfo={(id: number, name: string) => {
                dropdownSelectedOptions.selectedPitcherInfo = { id, name };
                chartingStore.fetchAndSortLiveGameData(
                  dropdownSelectedOptions.selectedGamePks
                );
              }}
              resetSelectedOptions={() => {
                navigationStore.resetSelectedOptions();
              }}
              fetchGamesForDate={() => navigationStore.fetchGamesForDate()}
            />
          )}
        </div>
      </div>
    );
  }
}

export default NavigationContainer;
