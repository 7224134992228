import { action, computed, observable } from "mobx";
import RootStore from "./RootStore";
import { ChartDataSets, ChartColor, Scriptable } from "chart.js";
import { PlayEvents } from "api/StatsApi";
import { labelToColorMap } from "constants/pitchTypes";
import { HandednessFilters } from "enums/handedFilters";

class ChartingStore {
  rootStore: RootStore;
  hoverBorderColor: ChartColor;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.hoverBorderColor = "rgb(54, 162, 235)";
  }

  private _pointBorderColor: Scriptable<ChartColor> = (context) => {
    const { dataset, dataIndex } = context;
    if (
      dataset === undefined ||
      dataset.data === undefined ||
      dataIndex === undefined
    ) {
      return "black";
    }
    const { pitch } = dataset.data[dataIndex] as {
      x: number;
      y: number;
      pitch: PlayEvents;
    };
    if (pitch.isSelected) return "black";

    const pitchType = pitch.details.type.code;
    return labelToColorMap[pitchType];
  };

  private _pointBorderWidth: Scriptable<number> = (context) => {
    const { dataset, dataIndex } = context;
    if (
      dataset === undefined ||
      dataset.data === undefined ||
      dataIndex === undefined
    ) {
      return 1;
    }
    const { pitch } = dataset.data[dataIndex] as {
      x: number;
      y: number;
      pitch: PlayEvents;
    };
    return pitch.isSelected ? 3 : 1;
  };

  /*
   * A map of pitch type -> array of pitch events for a given pitcher.
   * ex: "FF" -> All PitchEvents that were all categorized as FF
   */
  @observable pitchersDataByPitchType: Map<string, PlayEvents[]> = new Map<
    string,
    PlayEvents[]
  >();

  @observable handednessFilter: HandednessFilters = HandednessFilters.All;
  @observable loading: boolean = false;
  @observable selectedSingleChart = "View All";

  @computed get totalNumberOfPitches(): number {
    let pitchCount = 0;
    this.pitchersDataByPitchType.forEach((pitchList) => {
      pitchCount += pitchList.length;
    });
    return pitchCount;
  }

  @computed get spinByReleaseSpeed(): ChartDataSets[] {
    return Array.from(this.pitchersDataByPitchType.keys()).map((pitchType) => {
      const dataSet = this.pitchersDataByPitchType
        .get(pitchType)
        ?.map((pitch) => ({
          y: pitch.pitchData.startSpeed,
          x: pitch.pitchData.breaks.spinRate,
          pitch: pitch,
        }));

      return {
        label: pitchType,
        data: dataSet,
        pointRadius: 4,
        pointBackgroundColor: labelToColorMap[pitchType],
        pointBorderColor: this._pointBorderColor,
        pointBorderWidth: this._pointBorderWidth,
        pointHoverBorderColor: this.hoverBorderColor,
      };
    });
  }

  @computed get horizontalMovementBySpeed(): ChartDataSets[] {
    return Array.from(this.pitchersDataByPitchType.keys()).map((pitchType) => {
      const dataSet = this.pitchersDataByPitchType
        .get(pitchType)
        ?.map((pitch) => ({
          y: pitch.pitchData.startSpeed,
          x: pitch.pitchData.coordinates.pfxX,
          pitch: pitch,
        }));

      return {
        label: pitchType,
        data: dataSet,
        pointRadius: 4,
        pointBackgroundColor: labelToColorMap[pitchType],
        pointBorderColor: this._pointBorderColor,
        pointBorderWidth: this._pointBorderWidth,
        pointHoverBorderColor: this.hoverBorderColor,
      };
    });
  }

  @computed get verticalMovementBySpeed(): ChartDataSets[] {
    return Array.from(this.pitchersDataByPitchType.keys()).map((pitchType) => {
      const dataSet = this.pitchersDataByPitchType
        .get(pitchType)
        ?.map((pitch) => ({
          y: pitch.pitchData.startSpeed,
          x: pitch.pitchData.coordinates.pfxZ,
          pitch: pitch,
        }));

      return {
        label: pitchType,
        data: dataSet,
        pointRadius: 4,
        pointBackgroundColor: labelToColorMap[pitchType],
        pointBorderColor: this._pointBorderColor,
        pointBorderWidth: this._pointBorderWidth,
        pointHoverBorderColor: this.hoverBorderColor,
      };
    });
  }

  @computed get horizontalMovementByVerticalMovement(): ChartDataSets[] {
    return Array.from(this.pitchersDataByPitchType.keys()).map((pitchType) => {
      const dataSet = this.pitchersDataByPitchType
        .get(pitchType)
        ?.map((pitch) => ({
          y: pitch.pitchData.coordinates.pfxZ,
          x: pitch.pitchData.coordinates.pfxX,
          pitch: pitch,
        }));

      return {
        label: pitchType,
        data: dataSet,
        pointRadius: 4,
        pointBackgroundColor: labelToColorMap[pitchType],
        pointBorderColor: this._pointBorderColor,
        pointBorderWidth: this._pointBorderWidth,
        pointHoverBorderColor: this.hoverBorderColor,
      };
    });
  }

  @computed get horizontalMovementBySpin(): ChartDataSets[] {
    return Array.from(this.pitchersDataByPitchType.keys()).map((pitchType) => {
      const dataSet = this.pitchersDataByPitchType
        .get(pitchType)
        ?.map((pitch) => ({
          x: pitch.pitchData.breaks.spinRate,
          y: pitch.pitchData.coordinates.pfxX,
          pitch: pitch,
        }));

      return {
        label: pitchType,
        data: dataSet,
        pointRadius: 4,
        pointBackgroundColor: labelToColorMap[pitchType],
        pointBorderColor: this._pointBorderColor,
        pointBorderWidth: this._pointBorderWidth,
        pointHoverBorderColor: this.hoverBorderColor,
      };
    });
  }

  @computed get verticalMovementBySpin(): ChartDataSets[] {
    return Array.from(this.pitchersDataByPitchType.keys()).map((pitchType) => {
      const dataSet = this.pitchersDataByPitchType
        .get(pitchType)
        ?.map((pitch) => ({
          x: pitch.pitchData.breaks.spinRate,
          y: pitch.pitchData.coordinates.pfxZ,
          pitch: pitch,
        }));

      return {
        label: pitchType,
        data: dataSet,
        pointRadius: 4,
        pointBackgroundColor: labelToColorMap[pitchType],
        pointBorderColor: this._pointBorderColor,
        pointBorderWidth: this._pointBorderWidth,
        pointHoverBorderColor: this.hoverBorderColor,
      };
    });
  }

  @action fetchAndSortLiveGameData(gamePks: number[]): Promise<void> {
    const { statsApi, navigationStore } = this.rootStore;
    const { selectedPitcherInfo } = navigationStore.dropdownSelectedOptions;
    let tempPitchCategoryBucket: Map<string, PlayEvents[]> = new Map<
      string,
      PlayEvents[]
    >();
    this.loading = true;
    this.resetPitcherData();
    return statsApi.fetchLiveGameData(gamePks).then((data) => {
      data.forEach((gameData) => {
        const gamePk = gameData.gamePk;
        const dateTime = gameData.gameData.datetime.dateTime;
        const isGameScheduledTBD = gameData.gameData.status.startTimeTBD;
        // Limit data to the pitches for that selected pitcher and only show playEvents where isPitch === true
        gameData.liveData.plays.allPlays
          .filter(
            (play) =>
              (this.handednessFilter === HandednessFilters.All ||
                play.matchup.batSide.code === this.handednessFilter) &&
              play.playEvents.filter((event) => event.isPitch)
          )
          .forEach((play) => {
            play.playEvents
              .filter(
                (event) =>
                  event.defense &&
                  event.defense.pitcher.id === selectedPitcherInfo?.id
              )
              .forEach((event) => {
                const inningInfo = `${play.about.halfInning} ${play.about.inning}`;
                const atBatIndex = play.about.atBatIndex;
                if (event.isPitch && event.details.type) {
                  event.gamePk = gamePk;
                  event.inningInfo = inningInfo;
                  event.atBatIndex = atBatIndex;
                  event.dateTime = dateTime;
                  event.isGameScheduledTBD = isGameScheduledTBD;
                  event.isSelected = false;
                  if (!tempPitchCategoryBucket.has(event.details.type.code)) {
                    tempPitchCategoryBucket.set(event.details.type.code, [
                      event,
                    ]);
                  } else {
                    tempPitchCategoryBucket
                      .get(event.details.type.code)
                      ?.push(event);
                  }
                }
              });
          });
      });
      // Set results to observable map for rendering
      this.pitchersDataByPitchType = tempPitchCategoryBucket;
      this.loading = false;
    });
  }

  /*
  Toggles the isSelected boolean on the found pitch object to true or false. If isSelected is already true,
  it then becomes false. Likewise if already false.
   */
  @action updateSelectedPitches = (dataSet: ChartDataSets, index: number) => {
    if (dataSet.label) {
      const foundPitchesArray =
        this.pitchersDataByPitchType.get(dataSet.label) || [];
      if (foundPitchesArray) {
        const isPitchAlreadySelected = foundPitchesArray[index].isSelected;
        foundPitchesArray[index].isSelected = isPitchAlreadySelected
          ? false
          : true;
        this.pitchersDataByPitchType.set(dataSet.label, [...foundPitchesArray]);
      }
    }
  };

  @action resetPitcherData() {
    this.pitchersDataByPitchType = new Map<string, PlayEvents[]>();
  }

  @action selectAllPitches = () => {
    for (let [k, v] of this.pitchersDataByPitchType) {
      let selectAll = v.map((pitch) => {
        return {
          ...pitch,
          isSelected: true,
        };
      });
      this.pitchersDataByPitchType.set(k, selectAll);
    }
  };

  @action deselectAllPitches = () => {
    for (let [k, v] of this.pitchersDataByPitchType) {
      let selectAll = v.map((pitch) => {
        return {
          ...pitch,
          isSelected: false,
        };
      });
      this.pitchersDataByPitchType.set(k, selectAll);
    }
  };

  //returns an [] of all currently selected pitches
  @computed get allSelectedPitches() {
    let allSelectedPitches: PlayEvents[] = [];
    for (let pitches of this.pitchersDataByPitchType.values()) {
      allSelectedPitches.push(...pitches.filter((p) => p.isSelected));
    }
    return allSelectedPitches;
  }
}

export default ChartingStore;
