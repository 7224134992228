import React, { Component, ContextType } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import "antd/dist/antd.css";
import Login from "./containers/login";
import App from "./App";
import { rootStoreContext } from "./contexts";
import { Routes } from "./enums/routes";

class Routing extends Component {
  static contextType = rootStoreContext;
  context!: ContextType<typeof rootStoreContext>;

  render() {
    return (
      <Security authService={this.context.authService}>
        <BrowserRouter>
          <Switch>
            <Route exact={true} path={Routes.Login}>
              <Login />
            </Route>
            <Route exact={true} path={Routes.ImplicitCallback}>
              <LoginCallback />
            </Route>
            <Route path={Routes.Root}>
              <App />
            </Route>
          </Switch>
        </BrowserRouter>
      </Security>
    );
  }
}

export default Routing;
