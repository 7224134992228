import { notification } from "antd";
import { observable } from "mobx";
import { queueProcessor } from "mobx-utils";

export type MessageType = "success" | "error" | "warning";

interface Message {
  type: MessageType;
  message: string | React.ReactNode;
  description?: string;
}

class MessageStore {
  @observable messages: Message[] = [];

  constructor() {
    queueProcessor(this.messages, (m: Message) => this.process(m));
  }

  process = (m: Message) => {
    notification[m.type]({
      message: m.message,
      description: m.description,
      placement: "topRight",
    });
  };

  push = (m: Message): void => {
    this.messages.push(m);
    if (m.type === "error") {
      console.error(m.message);
    }
  };
}

export default MessageStore;
