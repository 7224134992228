import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import { Button, Layout } from "antd";
import styles from "./Login.module.scss";
import { Routes } from "enums/routes";

const Content = Layout.Content;

function Login() {
  const { authService, authState } = useOktaAuth();
  const params = new URLSearchParams(window.location.search);

  if (authState.isAuthenticated) {
    return <Redirect to={Routes.Root} />;
  }

  return (
    <Layout>
      <Content>
        <div className={styles["container"]}>
          <div className={styles["content"]}>
            <div className={styles.title}>Pitch Classification</div>
            <Button onClick={() => authService.login(params.get("redirectTo"))}>
              Login with Okta
            </Button>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default Login;
