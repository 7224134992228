interface GameLog {
  stats: Stats[];
}

export interface Stats {
  splits: Split[];
}

export interface Pitcher {
  firstName: string;
  lastName: string;
  lastFirstName: string;
  fullName: string;
  id: string;
}

export interface Split {
  isHome: boolean;
  player: Pitcher;
  team: {
    id: number;
    name: string;
    link: string;
    abbreviation: string;
  };
  opponent: {
    id: number;
    name: string;
    link: string;
    abbreviation: string;
  };
  date: string;
  game: {
    gamePk: number;
  };
}

interface LiveGame {
  gamePk: number;
  gameData: {
    datetime: {
      dateTime: string;
    };
    status: {
      startTimeTBD: boolean;
    };
  };
  liveData: {
    plays: {
      allPlays: Plays[];
    };
  };
}

interface Plays {
  about: {
    halfInning: string;
    atBatIndex: number;
    inning: number;
  };
  matchup: {
    pitcher: {
      id: number;
    };
    batSide: {
      code: string;
    };
  };
  playEvents: PlayEvents[];
}

export interface PlayEvents {
  playId: string;
  gamePk: number;
  atBatIndex: number;
  dateTime: string;
  isGameScheduledTBD: boolean;
  inningInfo: string;
  isPitch: boolean;
  pitchNumber: number;
  defense?: {
    pitcher: {
      id: number;
    };
  };
  details: {
    call: {
      description: string;
    };
    type: {
      code: string;
    };
  };
  count: {
    balls: number;
    strikes: number;
  };
  pitchData: {
    startSpeed: number;
    breaks: {
      spinRate: number;
    };
    coordinates: {
      pfxX: number; // Horizontal movement
      pfxZ: number; //Vertical movement
    };
  };
  isSelected: boolean;
}

interface Sport {
  sports: [
    {
      abbreviation: string;
      id: string;
    }
  ];
}

interface Schedule {
  dates: [
    {
      games: [
        {
          gamePk: string;
          gameType: string;
          teams: {
            away: {
              team: {
                id: string;
                abbreviation: string;
              };
            };
            home: {
              team: {
                id: string;
                abbreviation: string;
              };
            };
          };
        }
      ];
    }
  ];
}

interface ArsenalStats {
  stats: PitcherArsenal[];
}

interface PitcherArsenal {
  splits: PitcherArsenalSplit[];
}

interface PitcherArsenalSplit {
  stat: {
    percentage: number;
    count: number;
    totalPitches: number;
    type: {
      code: string;
      description: string;
    };
  };
}

const STATS_BASE_URL = process.env.REACT_APP_STATS_API_URL;

export default class StatsApi {
  fetchLiveGameData(gamePks: number[]): Promise<LiveGame[]> {
    const fetchUrls = gamePks.map(
      (gamePk) =>
        `${STATS_BASE_URL}/api/v1.1/game/${gamePk}/feed/live?hydrate=alignment`
    );
    return Promise.all(
      fetchUrls.map((url) =>
        fetch(url).then((response: Response) => response.json())
      )
    );
  }

  fetchGames(
    sportId: number,
    season: string,
    gameType: string,
    pitcherId: number
  ): Promise<GameLog> {
    return fetch(
      `${STATS_BASE_URL}/api/v1/people/${pitcherId}/stats?stats=gameLog&season=${season}&sportId=${sportId}&gameType=${gameType}&group=pitching&hydrate=team`
    ).then((response) => response.json());
  }

  //fetches all Sport
  fetchSportIds = async (): Promise<Sport> => {
    let resp = await fetch(`${STATS_BASE_URL}/api/v1/sports`);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };

  //fetches all gameTypes
  fetchGameTypes = async (): Promise<[{ id: string; description: string }]> => {
    let resp = await fetch(`${STATS_BASE_URL}/api/v1/gameTypes`);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };

  fetchLatestSeason = async (): Promise<{
    seasons: {
      seasonId: string;
    }[];
  }> => {
    let resp = await fetch(`${STATS_BASE_URL}/api/v1/seasons?sportId=1`);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };

  //NavigateByPitcher endpoint that fetches pitchers based on dropdown choices
  fetchAllPitchersForSeason = async (
    sportId: number,
    season: string,
    gameType: string
  ): Promise<GameLog> => {
    const fetchURL = `${STATS_BASE_URL}/api/v1/stats?stats=season&sportId=${sportId}&season=${season}&group=pitching&order=asc&playerPool=all&limit=5000&gameType=${gameType}&hydrate=person`;
    let resp = await fetch(fetchURL);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };

  //NavigateByDate endpt that fetches games for a selected date
  fetchGamesForDate = async (
    date: string,
    sportId: number
  ): Promise<Schedule> => {
    const fetchURL = `${STATS_BASE_URL}/api/v1/schedule?date=${date}&sportId=${sportId}&hydrate=team`;
    let resp = await fetch(fetchURL);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };

  //NavigateByDate endpt that fetches pitchers for a selected game
  fetchPitchersForDateNavigation = async (gamePk: string) => {
    const fetchURL = `${STATS_BASE_URL}/api/v1/game/${gamePk}/boxscore?hydrate=person`;
    let resp = await fetch(fetchURL);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };

  fetchPitcherArsenal = async (
    sportId: number,
    season: string,
    gameType: string,
    pitcherId: number
  ): Promise<ArsenalStats> => {
    const fetchURL = `${STATS_BASE_URL}/api/v1/people/${pitcherId}/stats?stats=pitchArsenal&season=${season}&sportId=${sportId}&gameType=${gameType}&group=pitching`;
    let resp = await fetch(fetchURL);
    if (!resp.ok) {
      throw new Error(`${resp.status}, ${resp.statusText}`);
    }
    return await resp.json();
  };
}
