import RootStore from "./RootStore";
import { action, observable } from "mobx";

class PitchClassifierStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable knownPitchTypes: string[] = [];

  @action async fetchPitcherArsenal() {
    const { statsApi, navigationStore } = this.rootStore;
    this.knownPitchTypes = [];
    if (navigationStore.dropdownSelectedOptions.selectedPitcherInfo?.id) {
      await statsApi
        .fetchPitcherArsenal(
          navigationStore.dropdownSelectedOptions.selectedSportId,
          navigationStore.dropdownSelectedOptions.selectedSeasons[0],
          navigationStore.dropdownSelectedOptions.selectedGameType,
          navigationStore.dropdownSelectedOptions.selectedPitcherInfo?.id
        )
        .then((data) => {
          data.stats.forEach((stat) => {
            stat.splits.forEach((split) => {
              this.knownPitchTypes.push(split.stat.type.code);
            });
          });
        })
        .catch((err) => console.log(err));
    } else {
      console.error("No Pitcher selected!");
    }
  }
}

export default PitchClassifierStore;
