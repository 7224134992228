import React, { Component } from "react";
import styles from "./NavigateByPitcher.module.scss";
import { Select, Checkbox, AutoComplete, Button } from "antd";
import { CheckboxChangeEvent, CheckboxProps } from "antd/lib/checkbox";
import { normalizeStringOfDiacritics } from "util/normalize";
import { Pitcher, Split } from "api/StatsApi";
import { GameType } from "stores/NavigationStore";
const { Option } = Select;

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface NavigateByPitcherProps {
  errorOccurred: boolean;
  sports: { abbreviation: string; id: string }[];
  seasons: string[];
  splits: Split[];
  gameTypes: GameType[];
  availableGamePks: number[];
  selectedGamePks: number[];
  currentSeason?: string;
  pitchers: Pitcher[];
  currentPitcherId?: number;
  setGameType: (gameType: string) => void;
  setPitcherInfo: (id: number, name: string) => void;
  setSportId: (sportId: number) => void;
  setSeason: (season: string[]) => void;
  fetchAllPitchers: () => void;
  setSelectedGamePks: (selectedGamePks: number[]) => void;
  setPitcherData: () => void;
  resetSelectedOptions: () => void;
  resetSelectedGamePks: () => void;
}

interface NavigateByPitcherState {
  searched: boolean;
  checkAll: boolean;
  pitcherInput?: string;
}

class NavigateByPitcher extends Component<
  NavigateByPitcherProps,
  NavigateByPitcherState
> {
  constructor(props: NavigateByPitcherProps) {
    super(props);
    this.state = {
      searched: false,
      checkAll: false,
      pitcherInput: "",
    };
  }

  componentDidMount() {
    const { setSportId, fetchAllPitchers, resetSelectedOptions } = this.props;
    resetSelectedOptions();
    setSportId(1);
    fetchAllPitchers();
  }

  componentDidUpdate(prevProps: NavigateByPitcherProps) {
    // If current props are coming in blank, we need to reset this controlled input field
    if (
      prevProps.currentPitcherId !== undefined &&
      this.props.currentPitcherId === undefined
    ) {
      this.setState({
        pitcherInput: "",
        checkAll: false,
        searched: false,
      });
    }
  }

  onSearch = () => {
    const { setPitcherData } = this.props;
    setPitcherData();
    this.setState({ searched: true });
  };

  selectGamePks(gamePks: number[]) {
    const { setSelectedGamePks, selectedGamePks } = this.props;
    const newSelectedGamePks = selectedGamePks.concat(gamePks);
    setSelectedGamePks(newSelectedGamePks);
  }

  deselectGamePks(gamePks: number[]) {
    const { setSelectedGamePks, selectedGamePks } = this.props;
    const newSelectedGamePks = selectedGamePks.filter(
      (gamePk) => !gamePks.includes(gamePk)
    );
    setSelectedGamePks(newSelectedGamePks);
  }

  render() {
    const {
      splits,
      sports,
      pitchers,
      gameTypes,
      seasons,
      selectedGamePks,
      setSelectedGamePks,
    } = this.props;

    const sportSelections = sports.map((item) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.abbreviation}
        </Option>
      );
    });
    const seasonSelection = seasons.map((item) => {
      return (
        <Option key={item} value={item}>
          {item}
        </Option>
      );
    });
    const gameTypeSelection = gameTypes.map((item) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.id}: {item.description}
        </Option>
      );
    });

    const pitcherOptions = pitchers.map((p) => {
      return { value: `${p.lastFirstName} (${p.id})` };
    });

    const gamePkSelectionMap =
      splits &&
      splits
        .map((item) => {
          const {
            date,
            isHome,
            opponent,
            team,
            game: { gamePk },
          } = item;
          const [year, month, day] = date.split("-");

          const homeTeam = isHome ? team : opponent;
          const awayTeam = isHome ? opponent : team;
          return {
            label: `${month}/${day} - ${awayTeam.abbreviation} @
            ${homeTeam.abbreviation}(${gamePk})`,
            value: gamePk,
            month: `${year}-${month}`,
          };
        })
        .reduce<{
          [key: string]: { label: string; value: number; month: string }[];
        }>((prev, curr) => {
          if (!prev[curr.month]) prev[curr.month] = [];
          prev[curr.month].push(curr);
          return prev;
        }, {});

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
      const { availableGamePks } = this.props;
      this.setState({ checkAll: e.target.checked });
      if (e.target.checked) {
        setSelectedGamePks(availableGamePks);
      } else {
        setSelectedGamePks([]);
      }
    };

    return (
      <div className={styles["sidebar-container"]}>
        <div className={styles.selections}>
          <Select
            className={styles.selection}
            labelInValue
            defaultValue={[{ value: 1, label: "MLB" }]}
            size="middle"
            placeholder="Select a Sport"
            onSelect={(sport) => {
              this.props.setSportId(sport.value);
              this.props.fetchAllPitchers();
            }}
          >
            {sportSelections}
          </Select>

          <Select
            mode="multiple"
            className={styles.selection}
            defaultValue={
              this.props.currentSeason ? [this.props.currentSeason] : [""]
            }
            size="middle"
            placeholder="Select a Season"
            allowClear={true}
            onChange={(season) => {
              this.props.setSeason(season);
              this.props.fetchAllPitchers();
            }}
          >
            {seasonSelection}
          </Select>

          <Select
            mode="multiple"
            className={styles.selection}
            defaultValue={["R"]}
            size="middle"
            placeholder="Select a Game Type"
            allowClear={true}
            onChange={(gameType) => {
              this.props.setGameType(gameType.toString());
              this.props.fetchAllPitchers();
            }}
          >
            {gameTypeSelection}
          </Select>

          <AutoComplete
            className={styles.selection}
            size="middle"
            onSelect={(pitcher) => {
              this.props.resetSelectedGamePks();
              let selectedPitcherId = pitcher.split("(")[1].split(")")[0];
              let selectedPitcherName = pitcher.split("(")[0];
              this.props.setPitcherInfo(
                parseInt(selectedPitcherId),
                selectedPitcherName
              );
            }}
            value={this.state.pitcherInput}
            onChange={(value) => this.setState({ pitcherInput: value })}
            allowClear={true}
            options={pitcherOptions}
            placeholder="Select a pitcher"
            filterOption={(inputValue, option) =>
              normalizeStringOfDiacritics(option!.value.toUpperCase()).includes(
                normalizeStringOfDiacritics(inputValue.toUpperCase())
              )
            }
          />

          <Button onClick={this.onSearch}>Search</Button>
        </div>
        {this.state.searched ? (
          this.props.errorOccurred ? (
            <div className={styles.message}>
              There are no games available for the selections
            </div>
          ) : (
            <div className={styles["available-games"]}>
              <div className={styles["game-select-all"]}>
                <h1 className={styles["games-title"]}>Games:</h1>

                <Checkbox
                  className={styles["check-all"]}
                  onChange={onCheckAllChange}
                  checked={
                    selectedGamePks.length !== 0 &&
                    splits.length === selectedGamePks.length
                  }
                  indeterminate={
                    selectedGamePks.length !== 0 &&
                    splits.length !== selectedGamePks.length
                  }
                >
                  Select All
                </Checkbox>
              </div>
              <div>
                {Object.keys(gamePkSelectionMap)
                  .sort()
                  .map((key) => {
                    const gamePkSelection = gamePkSelectionMap[key];

                    const checkboxes = gamePkSelection.map((option) => {
                      return (
                        <li key={option.value}>
                          <CheckboxWithValue
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                this.selectGamePks([e.target.value]);
                              } else {
                                this.deselectGamePks([e.target.value]);
                              }
                            }}
                            checked={selectedGamePks.includes(option.value)}
                            value={option.value}
                          >
                            {option.label}
                          </CheckboxWithValue>
                        </li>
                      );
                    });

                    const [year, month] = key
                      .split("-")
                      .map((x) => parseInt(x, 10));

                    return (
                      <div key={key} style={{ marginBottom: "1rem" }}>
                        <span
                          className={styles["month-header"]}
                          onClick={() => {
                            if (
                              !gamePkSelection
                                .map((o) => o.value)
                                .every((gamePk) =>
                                  selectedGamePks.includes(gamePk)
                                )
                            ) {
                              this.selectGamePks(
                                gamePkSelection.map((o) => o.value)
                              );
                            } else {
                              this.deselectGamePks(
                                gamePkSelection.map((o) => o.value)
                              );
                            }
                          }}
                        >
                          <span className={styles["month"]}>
                            {MONTHS[month - 1]}
                          </span>
                          <span className={styles["year"]}> {year}</span>
                        </span>
                        <ul className={styles["game-list"]}>{checkboxes}</ul>
                      </div>
                    );
                  })}
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

const CheckboxWithValue = (
  props: CheckboxProps & { value: string | number }
) => {
  return (
    <Checkbox
      onChange={(e) => {
        if (props.onChange) {
          e.target.value = props.value;
          props.onChange(e);
        }
      }}
      {...props}
    />
  );
};

export default NavigateByPitcher;
