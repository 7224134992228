import React, { Component } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { HandednessFilters } from "enums/handedFilters";

interface HandednessFilterProps {
  handedFilterValue: HandednessFilters;
  setHandednessFilter(value: HandednessFilters): void;
}

class HandednessFilter extends Component<HandednessFilterProps, {}> {
  render() {
    const { handedFilterValue, setHandednessFilter } = this.props;
    return (
      <>
        <Radio.Group
          value={handedFilterValue}
          onChange={(e: RadioChangeEvent) => {
            setHandednessFilter(e.target.value);
          }}
        >
          <Radio.Button value={HandednessFilters.Left}>LHH</Radio.Button>
          <Radio.Button value={HandednessFilters.Right}>RHH</Radio.Button>
          <Radio.Button value={HandednessFilters.All}>ALL</Radio.Button>
        </Radio.Group>
      </>
    );
  }
}

export default HandednessFilter;
