import React, { Component } from "react";
import { DatePicker, Select, AutoComplete } from "antd";
import styles from "./NavigateByDate.module.scss";
import moment from "moment";
import { Pitcher } from "api/StatsApi";
import { normalizeStringOfDiacritics } from "util/normalize";

const { Option } = Select;

interface NavigateByDateProps {
  setDate: (date: moment.Moment) => void;
  setGamePk: (gamePk: string) => void;
  setGameType: (gameType: string) => void;
  allGames: { gamePk: string; gameType: string; abbrev: string }[];
  sports: { abbreviation: string; id: string }[];
  pitchers: Pitcher[];
  currentPitcherId?: number;
  currentDateInput: string;
  currentGamePkInput: number[];
  setSportId: (sportId: number) => void;
  setPitcherInfo: (id: number, name: string) => void;
  resetSelectedOptions: () => void;
  fetchGamesForDate: () => void;
}

interface NavigateByDateState {
  pitcherInput?: string;
  gameInput?: string;
}

class NavigateByDate extends Component<
  NavigateByDateProps,
  NavigateByDateState
> {
  constructor(props: NavigateByDateProps) {
    super(props);
    this.state = {
      pitcherInput: "",
      gameInput: undefined,
    };
  }

  componentDidMount() {
    const { setSportId, resetSelectedOptions, fetchGamesForDate } = this.props;
    resetSelectedOptions();
    setSportId(1);
    fetchGamesForDate();
  }

  componentDidUpdate(prevProps: NavigateByDateProps) {
    // We need to reset some controlled input fields if props have changed.
    if (
      (prevProps.currentPitcherId !== undefined &&
        this.props.currentPitcherId === undefined) ||
      prevProps.currentDateInput !== this.props.currentDateInput
    ) {
      this.setState({
        pitcherInput: "",
        gameInput: undefined,
      });
    } else if (prevProps.currentGamePkInput !== this.props.currentGamePkInput) {
      this.setState({
        pitcherInput: "",
      });
    }
  }

  render() {
    const { pitchers, allGames, sports } = this.props;

    const sportSelections = sports.map((item) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.abbreviation}
        </Option>
      );
    });

    const gameOptions = allGames.map((game) => {
      return (
        <Option key={game.gamePk} value={game.gamePk + "," + game.gameType}>
          {game.abbrev} ({game.gamePk})
        </Option>
      );
    });

    const pitcherOptions = pitchers.map((item) => {
      return { value: `${item.lastFirstName} (${item.id})` };
    });

    return (
      <div className={styles.container}>
        <Select
          className={styles.selection}
          labelInValue
          defaultValue={[{ value: 1, label: "MLB" }]}
          size="middle"
          placeholder="Select a Sport"
          onSelect={(sport) => {
            this.props.setSportId(sport.value);
          }}
        >
          {sportSelections}
        </Select>
        <DatePicker
          className={styles.selection}
          defaultValue={moment()} //default value is date today/now
          onSelect={(date) => this.props.setDate(moment(date))}
        />
        <Select
          placeholder="Select a game"
          size="middle"
          className={styles.selection}
          onSelect={(selected) => {
            let [gamePk, gameType] = selected.split(",");
            this.props.setGamePk(gamePk);
            this.props.setGameType(gameType);
          }}
          value={this.state.gameInput}
          onChange={(value) => this.setState({ gameInput: value })}
        >
          {gameOptions}
        </Select>
        <AutoComplete
          className={styles.selection}
          size="middle"
          options={pitcherOptions}
          onSelect={(pitcher) => {
            let selectedPitcherId = pitcher.split("(")[1].split(")")[0];
            let selectedPitcherName = pitcher.split("(")[0];
            this.props.setPitcherInfo(
              parseInt(selectedPitcherId),
              selectedPitcherName
            );
          }}
          placeholder="Select a pitcher"
          value={this.state.pitcherInput}
          onChange={(value) => this.setState({ pitcherInput: value })}
          allowClear={true}
          filterOption={(inputValue, option) =>
            normalizeStringOfDiacritics(option!.value.toUpperCase()).includes(
              normalizeStringOfDiacritics(inputValue.toUpperCase())
            )
          }
        />
      </div>
    );
  }
}

export default NavigateByDate;
