import RootStore from "stores/RootStore";
import { PlayEvents } from "./StatsApi";
import { MessageType } from "stores/MessageStore";

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

interface BinApiPitchEvent {
  playId: string;
  gamePk: number;
  pitchType: string;
  pitcherId: number;
}

interface ApiResponse<T> {
  data: T[];
  errors: string[];
  requestStatus: RequestStatus;
}

interface RequestStatus {
  statusCode: number;
  msg: string;
}

type BinApiResult = {
  requestId: string;
  response: string;
  statusCode: number;
};

export default class PitchClassifier {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  private _transformPitchData(
    playerId: number,
    pitchData: PlayEvents[]
  ): BinApiPitchEvent[] {
    return pitchData.map((pitch) => ({
      gamePk: pitch.gamePk,
      playId: pitch.playId,
      pitchType: pitch.details.type?.code,
      pitcherId: playerId,
    }));
  }

  private _processMessage(message: string, type: MessageType) {
    const { messageStore } = this.rootStore;
    messageStore.push({ type, message });
    if (type === "error") {
      throw new Error(message);
    }
  }

  deleteTrainingData(playerId: number) {
    return fetch(`${BASE_URL}/player/${playerId}/deleteTrainingData`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.ok) {
        this._processMessage("Delete training for " + playerId, "success");
        return true;
      } else {
        this._processMessage(
          "Failed to delete training for playerId " + playerId,
          "error"
        );
      }
    });
  }

  classifyPitches(
    playerId: number,
    pitchData: PlayEvents[]
  ): Promise<ApiResponse<BinApiResult>> {
    return fetch(`${BASE_URL}/player/${playerId}/classifyPitches`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pitchUpdates: this._transformPitchData(playerId, pitchData),
      }),
    }).then((r) => {
      if (r.ok) {
        this._processMessage("Classify successful", "success");
        return r.json();
      } else {
        this._processMessage("Failed to classify pitch events", "error");
      }
    });
  }

  trainPitcher(
    playerId: number,
    pitchData: PlayEvents[]
  ): Promise<ApiResponse<BinApiResult>> {
    return fetch(`${BASE_URL}/player/${playerId}/addToTrainingData`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pitchUpdates: this._transformPitchData(playerId, pitchData),
      }),
    }).then((r) => {
      if (r.ok) {
        this._processMessage("Saved training data", "success");
        return r.json();
      } else {
        this._processMessage("Failed to save training", "error");
      }
    });
  }

  trainNeuralNet(playerId: number): Promise<BinApiResult> {
    return fetch(`${BASE_URL}/player/${playerId}/trainNeuralNetForPitcher`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.ok) {
        return r.json();
      } else {
        this._processMessage(
          "Failed to train NN for playerId " + playerId,
          "error"
        );
      }
    });
  }
}
