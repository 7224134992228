import React, { Component } from "react";
import { Select, Button } from "antd";
import styles from "./ClassifierButton.module.scss";

const { Option } = Select;

export enum PitcherArsenalValues {
  Yes = "2",
  Maybe = "1",
  No = "0",
}

interface ButtonProps {
  item: {
    label: string;
    color: string;
  };
  value: string;
  onSelect(value: string, pitchType: string): void;
  onClick(value: string): void;
}

export default class ClassifierButton extends Component<ButtonProps> {
  render() {
    const { item, value, onClick, onSelect } = this.props;

    return (
      <div className={styles["pitch-type"]}>
        <Button
          onClick={() => onClick(item.label)}
          value={item.label}
          disabled={value !== PitcherArsenalValues.Yes}
        >
          <div>{item.label}</div>
        </Button>
        <svg key={item.label} width="30" height="30">
          <circle cx="15" cy="15" r="8" fill={item.color} />
        </svg>
        <Select
          value={value}
          className={styles["select"]}
          onChange={(pitchValue) => onSelect(pitchValue, item.label)}
        >
          <Option value={PitcherArsenalValues.No}>
            {PitcherArsenalValues.No}
          </Option>
          <Option value={PitcherArsenalValues.Maybe}>
            {PitcherArsenalValues.Maybe}
          </Option>
          <Option value={PitcherArsenalValues.Yes}>
            {PitcherArsenalValues.Yes}
          </Option>
        </Select>
      </div>
    );
  }
}
