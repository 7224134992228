import React, { Component, ContextType } from "react";
import styles from "./Footer.module.scss";
import { observer } from "mobx-react";
import { rootStoreContext } from "contexts";

@observer
class Footer extends Component {
  static contextType = rootStoreContext;
  context!: ContextType<typeof rootStoreContext>;

  render() {
    const { navigationStore } = this.context;
    const { selectedPitcherInfo } = navigationStore.dropdownSelectedOptions;
    const pitcherId = selectedPitcherInfo?.id;

    return (
      <div className={styles.container}>
        {pitcherId ? (
          <>
            <a
              href={`https://research.mlb.com/players/${pitcherId}/stats#statGroup=pitching&orgId=1&gameType=R`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Research Tool
            </a>
            <a
              href={`https://baseballsavant.mlb.com/savant-player/${pitcherId}`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Savant Player Page
            </a>
            <a
              href={`https://baseballsavant.mlb.com/pitchmix#${pitcherId}`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Pitch Mix
            </a>
            <a
              href={`http://mlb-bdata-prod-us-east-1-pitch-arsenal-plots.s3-website-us-east-1.amazonaws.com/${pitcherId}_arsenal.html`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Pitch 3D
            </a>
            <a
              href={`https://baseballsavant.mlb.com/visuals/statcast-pitch-distribution?playerId=${pitcherId}`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Velocity Distribution
            </a>
            <a
              href={`http://www.brooksbaseball.net/outcome.php?player=${pitcherId}`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Brooks Baseball
            </a>
          </>
        ) : null}
      </div>
    );
  }
}

export default Footer;
