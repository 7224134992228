import React, { Component, ContextType } from "react";
import { observer } from "mobx-react";
import Chart from "components/chart";
import Spinner from "components/spinner";
import styles from "./ChartingContainer.module.scss";
import { rootStoreContext } from "contexts";
import { ChartDataSets } from "chart.js";

interface ChartProperties {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  storeData: ChartDataSets[];
}

@observer
class ChartingContainer extends Component {
  static contextType = rootStoreContext;
  context!: ContextType<typeof rootStoreContext>;

  //Either renders all charts, or renders a single chart based on chartingStore.selectedSingleChart value
  chartsToRender = (chartProperties: ChartProperties[]) => {
    const { chartingStore } = this.context;
    switch (chartingStore.selectedSingleChart) {
      case "View All":
        //render all charts
        return chartProperties.map((chart) => {
          return (
            <Chart
              key={chart.title}
              title={chart.title}
              xAxisTitle={chart.xAxisTitle}
              yAxisTitle={chart.yAxisTitle}
              storeData={chart.storeData}
              updateSelectedPitches={chartingStore.updateSelectedPitches}
              deselectAllPitches={chartingStore.deselectAllPitches}
              fullSizeChart={false}
            ></Chart>
          );
        });
      default:
        //render a single chart
        const singleChart = chartProperties.find(
          (chart) => chart.title === chartingStore.selectedSingleChart
        );
        if (singleChart) {
          return (
            <Chart
              key={singleChart.title}
              title={singleChart.title}
              xAxisTitle={singleChart.xAxisTitle}
              yAxisTitle={singleChart.yAxisTitle}
              storeData={singleChart.storeData}
              updateSelectedPitches={chartingStore.updateSelectedPitches}
              deselectAllPitches={chartingStore.deselectAllPitches}
              fullSizeChart={true}
            ></Chart>
          );
        }
    }
  };

  render() {
    const { chartingStore } = this.context;

    const chartGenerator: ChartProperties[] = [
      {
        title: "H Movement x V Movement",
        xAxisTitle: "H Movement",
        yAxisTitle: "V Movement",
        storeData: chartingStore.horizontalMovementByVerticalMovement,
      },
      {
        title: "H Movement x Speed",
        xAxisTitle: "H Movement",
        yAxisTitle: "Speed",
        storeData: chartingStore.horizontalMovementBySpeed,
      },
      {
        title: "V Movement x Speed",
        xAxisTitle: "V Movement",
        yAxisTitle: "Speed",
        storeData: chartingStore.verticalMovementBySpeed,
      },
      {
        title: "Spin x Speed",
        xAxisTitle: "Spin",
        yAxisTitle: "Speed",
        storeData: chartingStore.spinByReleaseSpeed,
      },
      {
        title: "H Movement x Spin",
        xAxisTitle: "Spin",
        yAxisTitle: "H Movement",
        storeData: chartingStore.horizontalMovementBySpin,
      },
      {
        title: "V Movement x Spin",
        xAxisTitle: "Spin",
        yAxisTitle: "V Movement",
        storeData: chartingStore.verticalMovementBySpin,
      },
    ];

    return (
      <div className={styles.container}>
        {chartingStore.loading ? (
          <div className={styles["loading-container"]}>
            <Spinner size="large" />
          </div>
        ) : null}

        {this.chartsToRender(chartGenerator)}
      </div>
    );
  }
}

export default ChartingContainer;
