import React, { Component, ContextType } from "react";
import styles from "./PitchClassifierContainer.module.scss";
import { pitchTypes } from "constants/pitchTypes";
import ClassifierButton from "components/classifierButton/ClassifierButton";
import HandednessFilter from "components/handednessFilter/HandednessFilter";
import { observer } from "mobx-react";
import { HandednessFilters } from "enums/handedFilters";
import { rootStoreContext } from "contexts";
import { PitcherArsenalValues } from "components/classifierButton/ClassifierButton";

@observer
class PitchClassifierContainer extends Component {
  static contextType = rootStoreContext;
  context!: ContextType<typeof rootStoreContext>;

  generateClassifierButtons = () => {
    const { pitchClassifierStore } = this.context;
    const buttons = pitchTypes.map((pitchType) => {
      const value = pitchClassifierStore.knownPitchTypes.includes(
        pitchType.label
      )
        ? "2"
        : "0";
      return (
        <ClassifierButton
          key={pitchType.label}
          item={pitchType}
          value={value}
          onSelect={this.updateKnownPitchTypes}
          onClick={this.changePitchType}
        />
      );
    });
    return buttons;
  };

  updateKnownPitchTypes = (value: string, pitchType: string) => {
    const { pitchClassifierStore } = this.context;
    if (
      value === PitcherArsenalValues.Yes ||
      value === PitcherArsenalValues.Maybe
    ) {
      pitchClassifierStore.knownPitchTypes.push(pitchType);
    } else {
      pitchClassifierStore.knownPitchTypes.splice(
        pitchClassifierStore.knownPitchTypes.findIndex(
          (type) => pitchType === type
        ),
        1
      );
    }
  };

  /*
   * Loop over all selecetd pitches and change their pitch types to the passed
   * in value as well as change which bucket they appear in.
   */
  changePitchType = (value: string) => {
    const { chartingStore } = this.context;
    chartingStore.allSelectedPitches.forEach((selectedPitch) => {
      if (selectedPitch.details.type) {
        const removedFromThisBucket = chartingStore.pitchersDataByPitchType
          .get(selectedPitch.details.type?.code)
          ?.filter((pitch) => pitch.playId !== selectedPitch.playId)
          ?.map((pitch) => pitch);
        if (removedFromThisBucket) {
          chartingStore.pitchersDataByPitchType.set(
            selectedPitch.details.type.code,
            removedFromThisBucket
          );
        }
        let addToThisPitchBucket = chartingStore.pitchersDataByPitchType.get(
          value
        );
        if (addToThisPitchBucket === undefined) {
          addToThisPitchBucket = [];
        }
        selectedPitch.details.type.code = value;
        addToThisPitchBucket.push(selectedPitch);
        chartingStore.pitchersDataByPitchType.set(value, addToThisPitchBucket);
      }
    });
  };

  render() {
    const buttons = this.generateClassifierButtons();
    const { chartingStore } = this.context;
    return (
      <div className={styles.container}>
        <div className={styles["buttons-container"]}>{buttons}</div>
        <div className={styles["handedness-container"]}>
          <HandednessFilter
            handedFilterValue={chartingStore.handednessFilter}
            setHandednessFilter={(filterValue: HandednessFilters) => {
              chartingStore.handednessFilter = filterValue;
            }}
          />
        </div>
      </div>
    );
  }
}

export default PitchClassifierContainer;
