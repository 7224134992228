import React from "react";
import { Spin } from "antd";
import { SpinProps } from "antd/lib/spin";
import { LoadingOutlined } from "@ant-design/icons";

const loadingOutlined = <LoadingOutlined style={{ fontSize: 96 }} spin />;

function Spinner(props: SpinProps) {
  return <Spin indicator={loadingOutlined} {...props} />;
}

export default Spinner;
