import React, { Component } from "react";
import Chart, { ChartPoint, ChartDataSets } from "chart.js";
import debounce from "lodash.debounce";
import moment from "moment";
import styles from "./Chart.module.scss";
import { PlayEvents } from "api/StatsApi";
import classNames from "classnames";

interface ChartProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  storeData: ChartDataSets[];
  updateSelectedPitches(dataSet: ChartDataSets, index: number): void;
  deselectAllPitches(): void;
  fullSizeChart: boolean;
}

interface SelectionRectangle {
  startingPointX: number;
  startingPointY: number;
  width: number;
  height: number;
}

class PitchingChart extends Component<ChartProps, {}> {
  chartReference: React.RefObject<HTMLCanvasElement>;
  selectionReference: React.RefObject<HTMLCanvasElement>;
  chartInstance: any;
  canvasCtx?: CanvasRenderingContext2D;
  selecting: boolean = false;
  chartMouseDownX: number = 0;
  chartMouseDownY: number = 0;
  chartMouseUpX: number = 0;
  chartMouseUpY: number = 0;
  rectangleMouseMoveStartX: number = 0;
  rectangleMouseMoveStartY: number = 0;
  numberOfSelections: number = 0; // Helps us decide if we selected/clicked on 'nothing'

  constructor(props: ChartProps) {
    super(props);
    this.chartReference = React.createRef();
    this.selectionReference = React.createRef();
  }

  drawRect = (rectCoords: SelectionRectangle) => {
    const { startingPointX, startingPointY, width, height } = rectCoords;
    if (this.canvasCtx && this.selectionReference.current) {
      const scale = window.devicePixelRatio;
      // Clear previous rectangle
      this.clearRect();

      // Draw selection rectangle based on mouse event coordinates passed in.
      this.canvasCtx.beginPath();
      this.canvasCtx.fillStyle = "rgba(0, 157, 240, 0.2)";
      this.canvasCtx.strokeStyle = "rgba(0, 157, 240, 1.0)";
      this.canvasCtx.lineWidth = 0.2;
      this.canvasCtx.rect(
        startingPointX * scale,
        startingPointY * scale,
        width * scale,
        height * scale
      );
      this.canvasCtx.fill();
      this.canvasCtx.stroke();
    }
  };

  clearRect = () => {
    if (this.canvasCtx && this.selectionReference.current) {
      this.canvasCtx.clearRect(
        0,
        0,
        this.selectionReference.current.width,
        this.selectionReference.current.height
      );
    }
  };

  getChartsXYAxisForMouseEvent = (
    event: React.MouseEvent<HTMLElement>,
    chart: any
  ) => {
    const yTop = chart.chartArea.top;
    const yBottom = chart.chartArea.bottom;
    const yMin = chart.scales["y-axis-1"].min;
    const yMax = chart.scales["y-axis-1"].max;
    let newY = 0;

    if (
      event.nativeEvent.offsetY <= yBottom &&
      event.nativeEvent.offsetY >= yTop
    ) {
      newY = Math.abs((event.nativeEvent.offsetY - yTop) / (yBottom - yTop));
      newY = (newY - 1) * -1;
      newY = newY * Math.abs(yMax - yMin) + yMin;
    }

    const xTop = chart.chartArea.left;
    const xBottom = chart.chartArea.right;
    const xMin = chart.scales["x-axis-1"].min;
    const xMax = chart.scales["x-axis-1"].max;
    let newX = 0;

    if (
      event.nativeEvent.offsetX <= xBottom &&
      event.nativeEvent.offsetX >= xTop
    ) {
      newX = Math.abs((event.nativeEvent.offsetX - xTop) / (xBottom - xTop));
      newX = newX * Math.abs(xMax - xMin) + xMin;
    }

    return { x: newX, y: newY };
  };

  pointSelectionHandler = (evt: MouseEvent) => {
    const { updateSelectedPitches } = this.props;
    const selectedPoint = this.chartInstance.getElementAtEvent(evt)[0];
    if (selectedPoint) {
      const selectedPointsChartDataSet =
        selectedPoint._chart.data.datasets[selectedPoint._datasetIndex];
      const value = selectedPointsChartDataSet.data[selectedPoint._index];
      updateSelectedPitches(selectedPointsChartDataSet, selectedPoint._index);
      console.log("You've clicked on play_id: " + value.pitch.playId);
    } else {
      if (!this.numberOfSelections) {
        this.props.deselectAllPitches();
      }
    }
  };

  keepCanvasSizesInSync = () => {
    /*
     * Chart.js is responsively changing the size of the canvas element it manages (this.chartReference).
     * Let's make sure we mimic it's style adjustments over to the selection canvas (this.selectionReference).
     */
    if (this.selectionReference.current && this.chartReference.current) {
      this.selectionReference.current.style.width = this.chartReference.current.style.width;
      this.selectionReference.current.width = this.chartReference.current.width;
      this.selectionReference.current.style.height = this.chartReference.current.style.height;
      this.selectionReference.current.height = this.chartReference.current.height;
    }
  };

  componentDidMount() {
    window.addEventListener("resize", debounce(this.keepCanvasSizesInSync, 10));
    if (this.chartReference.current && this.selectionReference.current) {
      const { title, xAxisTitle, yAxisTitle, storeData } = this.props;
      this.canvasCtx =
        this.selectionReference.current?.getContext("2d") || undefined;

      const dataSet = {
        datasets: storeData,
      };

      this.chartInstance = new Chart(this.chartReference.current, {
        type: "scatter",
        data: dataSet,
        options: {
          onClick: this.pointSelectionHandler,
          animation: {
            duration: 0,
          },
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: xAxisTitle,
                },
                position: "bottom",
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: yAxisTitle,
                },
                position: "bottom",
              },
            ],
          },
          hover: {
            animationDuration: 0,
            mode: "point",
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: false,
            mode: "point",
            custom: (tooltipModel) => {
              // Tooltip Element
              let tooltipEl = document.getElementById("chartjs-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0 || this.selecting) {
                tooltipEl.style.opacity = "0";
                tooltipEl.style.display = "none";
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // Grab proper dataSet and dataPoint from chartInstance
              const point = tooltipModel.dataPoints[0];
              const dataSetIndex: number =
                point.datasetIndex !== undefined ? point.datasetIndex : -1;
              const pointIndexWithinDataSet: number =
                point.index !== undefined ? point.index : -1;

              if (dataSetIndex < 0 || pointIndexWithinDataSet < 0) {
                console.error("Invalid dataSet/dataIndex");
                return false;
              }

              const pitchInfo: PlayEvents = this.chartInstance.data.datasets[
                dataSetIndex
              ].data[pointIndexWithinDataSet].pitch;
              const momentInstance = pitchInfo.isGameScheduledTBD
                ? moment.utc
                : moment;

              const displayPitchConfig = {
                gamePk: ["GamePk", pitchInfo.gamePk],
                gameDate: [
                  "Game Date",
                  momentInstance(pitchInfo.dateTime).format("YYYY-MM-DD"),
                ],
                inningInfo: ["Inning", pitchInfo.inningInfo],
                atBat: ["AtBat", pitchInfo.atBatIndex + 1],
                pitchNumber: ["Pitch Num", pitchInfo.pitchNumber],
                outcome: ["Outcome", pitchInfo.details.call.description],
                pitchType: ["Pitch Type", pitchInfo.details.type.code],
              };

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];

                let innerHtml = "<thead>";

                titleLines.forEach(function (title) {
                  innerHtml += "<tr><th>" + title + "</th></tr>";
                });
                innerHtml += "</thead><tbody>";

                Object.values(displayPitchConfig).forEach(function (
                  lineOfInfo
                ) {
                  const [label, value] = lineOfInfo;
                  innerHtml += `<tr><td>${label}: ${value}</td></tr>`;
                });
                innerHtml += "</tbody>";

                const tableRoot = tooltipEl.querySelector("table");
                if (tableRoot) {
                  tableRoot.innerHTML = innerHtml;
                }
              }

              const position = this.chartInstance.canvas.getBoundingClientRect();

              // Display, position, and set styles
              tooltipEl.style.backgroundColor = "rgba(0, 0, 0, .7)";
              tooltipEl.style.borderRadius = "0.4rem";
              tooltipEl.style.color = "#fff";
              tooltipEl.style.opacity = "1";
              tooltipEl.style.display = "block";
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left =
                position.left +
                window.pageXOffset +
                tooltipModel.caretX +
                5 +
                "px";
              tooltipEl.style.top =
                position.top +
                window.pageYOffset +
                tooltipModel.caretY +
                5 +
                "px";
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
            },
          },
        },
      });

      this.keepCanvasSizesInSync();
    } else {
      console.error("No canvas element reference!");
    }
  }

  componentDidUpdate() {
    const { storeData } = this.props;
    //clears the old previous data and assigns it the new data rather than having to destroy/recreate chart instance
    this.chartInstance.data.datasets = [];
    this.chartInstance.data.datasets = storeData;
    this.chartInstance.update();
  }

  componentWillUnmount() {
    window.addEventListener("resize", debounce(this.keepCanvasSizesInSync, 10));
  }

  render() {
    const { updateSelectedPitches } = this.props;

    return (
      <div
        className={classNames(styles["chart-container"], {
          [styles["full-size-chart"]]: this.props.fullSizeChart,
        })}
        onContextMenu={(e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          const selectedPoint = this.chartInstance.getElementAtEvent(e)[0];
          if (selectedPoint) {
            const selectedPointsChartDataSet =
              selectedPoint._chart.data.datasets[selectedPoint._datasetIndex];
            const value = selectedPointsChartDataSet.data[selectedPoint._index];
            const resarchToolLink = `https://research.mlb.com/games/${value.pitch.gamePk}/plays/${value.pitch.playId}`;
            window.open(resarchToolLink);
          }
        }}
        onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
          const axisXY = this.getChartsXYAxisForMouseEvent(
            event,
            this.chartInstance
          );

          this.chartMouseDownX = axisXY.x;
          this.chartMouseDownY = axisXY.y;

          // Only capture selection coordinates if we're left-clicking
          if (event.button === 0 && !this.selecting) {
            this.selecting = true;
            this.rectangleMouseMoveStartX = event.nativeEvent.offsetX;
            this.rectangleMouseMoveStartY = event.nativeEvent.offsetY;
          }
        }}
        onMouseMove={(event: React.MouseEvent<HTMLElement>) => {
          if (this.selecting) {
            this.drawRect({
              startingPointX: this.rectangleMouseMoveStartX,
              startingPointY: this.rectangleMouseMoveStartY,
              width: event.nativeEvent.offsetX - this.rectangleMouseMoveStartX,
              height: event.nativeEvent.offsetY - this.rectangleMouseMoveStartY,
            });
          }
        }}
        onMouseUp={(event: React.MouseEvent<HTMLElement>) => {
          if (this.selecting) {
            this.numberOfSelections = 0;
            const chartInstance = this.chartInstance;
            const axisXY = this.getChartsXYAxisForMouseEvent(
              event,
              this.chartInstance
            );
            this.chartMouseUpX = axisXY.x;
            this.chartMouseUpY = axisXY.y;

            if (chartInstance?.data.datasets) {
              chartInstance?.data.datasets.forEach(
                (dataSet: ChartDataSets | undefined) => {
                  if (this.selecting) {
                    this.rectangleMouseMoveStartX = 0;
                    this.rectangleMouseMoveStartY = 0;
                    let [xMin, xMax] =
                      this.chartMouseUpX > this.chartMouseDownX
                        ? [this.chartMouseDownX, this.chartMouseUpX]
                        : [this.chartMouseUpX, this.chartMouseDownX];
                    let [yMin, yMax] =
                      this.chartMouseUpY > this.chartMouseDownY
                        ? [this.chartMouseDownY, this.chartMouseUpY]
                        : [this.chartMouseUpY, this.chartMouseDownY];

                    if (dataSet?.data) {
                      const chartPoints: ChartPoint[] = dataSet.data as ChartPoint[];
                      chartPoints.forEach(
                        (point: ChartPoint, index: number) => {
                          if (
                            point.x &&
                            point.x >= xMin &&
                            point.x <= xMax &&
                            point.y &&
                            point.y >= yMin &&
                            point.y <= yMax
                          ) {
                            this.numberOfSelections++;
                            updateSelectedPitches(dataSet, index);
                          }
                        }
                      );
                    }
                  }
                }
              );
            }
            this.selecting = false;
            this.clearRect();
          }
        }}
        onMouseOut={() => {
          // Left chart area? Reset it all.
          this.selecting = false;
          this.chartMouseDownX = 0;
          this.chartMouseDownY = 0;
          this.chartMouseUpX = 0;
          this.chartMouseUpY = 0;
          this.clearRect();
        }}
      >
        <canvas ref={this.chartReference} />
        <canvas className={styles.selection} ref={this.selectionReference} />
      </div>
    );
  }
}

export default PitchingChart;
